.nav-container {
    font-family: "Open Sans", "Droid Sans", Tahoma, Arial, sans-serif;
    font-size: 13px;
    line-height: 1.42857143;
    color: #7c878e;
    background-color: white;
    box-sizing: border-box;
    display: block;
    z-index: 1050;
    min-height: 110px;
    margin-bottom: 18px;
    border: 1px solid transparent;
    border-width: 0 0 1px;
    border-color: #eeeeee;
    top: 3px !important;
    bottom: 3px !important;
    border-bottom: solid 1px #e1e4e5;
}

.nav-container h3 {
    font-size: 20px;
}

.navbar-container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 50px;
    padding-right: 50px;
}

.navbar-header {
    float: left;
}

.app-name {
    font-size: 25px;
    display: inline-block;
    padding: 25px 0 25px 0;
    text-decoration: none;
    font-weight: 600;
    position: absolute;
}

.navbar-right {
    float: right !important;
    margin-right: -16px;
}

.navbar-nav {
    float: left;
    flex-direction: row;
    margin: 0;
    display: flex;
    padding-left: 0;
    list-style: none;
}

.navbar-nav  li {
    position: relative;
    display: block;
    float: left;
    align-self: center;
}

.navbar-logout {
    display: none;
}

.navbar-nav > li.navbar-logout a {
    text-transform: uppercase; 
}

.navbar-nav > li a {
    line-height: 30px;
    position: relative;
    display: inline-block;
    padding: 10px;
    text-decoration: none;
    color: #7c878e;
    font-weight: 600;
    background-color: white;
}

.user-info {
    text-align: left;
    padding: 15px 30px 15px 30px;
    margin-left: 10px;
    margin-right: 10px;
}

.user-info .user-name {
    font-size: 14px;
    white-space: normal;
    margin-bottom: 5px;
}

.user-info .user-tenant {
    padding-top: 1px;
    font-size: 12px;
    white-space: normal;
    margin-bottom: 0;
}

.logo-teta {
    height: 70px;
    margin-right: 40px;
}
